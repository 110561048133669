import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { valibotResolver } from '@hookform/resolvers/valibot';
import { useDidUpdate } from '@toss/react';
import { useRequestEmailLoginCode, ZepQuizApiError } from '@zep/api';
import { IndicatorIcon } from '@zep/components/IndicatorIcon';
import { useAlert, useDelayedLoading, useHandleEnterKey } from '@zep/hooks';
import { Input } from '@zep/ui';
import { Button } from '@zep/ui_v3';
import { cn } from '@zep/utils';
import { useTranslation } from 'next-i18next';
import { email, InferInput, maxLength, nonEmpty, object, pipe, string } from 'valibot';
export const EmailInputForm = (props: {
  onSuccess: (email: string) => void;
}) => {
  const _alert = useAlert();
  const {
    t
  } = useTranslation(['common']);
  const {
    register,
    handleSubmit,
    getValues,
    setFocus,
    watch,
    formState: {
      errors
    }
  } = useForm<EmailSchemaType>({
    resolver: valibotResolver(EmailSchema),
    mode: 'onSubmit'
  });
  const {
    mutateAsync,
    isPending
  } = useRequestEmailLoginCode({
    mutation: {
      onSuccess: () => {
        props.onSuccess(getValues('email'));
      }
    }
  });
  const watchedEmail = watch('email');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const isRequiredError = (errors['email']?.message || '').length > 0;
  const isError = isRequiredError || !!errorMessage;
  const requiredEmail = t('auth.error.requiredEmail');
  const isLoading = useDelayedLoading(isPending);
  const handleKeyDown = useHandleEnterKey();
  const onSubmit = async () => {
    try {
      setErrorMessage(null);
      await mutateAsync({
        data: {
          email: getValues('email') || ''
        }
      });
    } catch (_error) {
      if (_error instanceof ZepQuizApiError) {
        if (_error.status === 'TOO_MANY_SIGNIN_TRIAL') {
          setErrorMessage(' ');
          _alert({
            type: 'alert',
            title: t('auth.error.emailRequestExceeded'),
            content: t('auth.error.emailRequestExceededDetail')
          }).then(() => setFocus('email'));
          return;
        }
        switch (_error.httpStatus) {
          case 400:
            {
              return setErrorMessage(requiredEmail);
            }
          case 429:
            {
              setErrorMessage(t('auth.error.exceeded'));
              return;
            }
          default:
            setErrorMessage(t('common.error.unknown'));
            return;
        }
      }
      setErrorMessage(t('common.error.unknown'));
      return;
    }
  };
  useDidUpdate(() => {
    if (watchedEmail) {
      setErrorMessage(null);
    }
  }, [watchedEmail]);
  return <div className={'flex w-full flex-col gap-xs'} data-sentry-component="EmailInputForm" data-sentry-source-file="EmailInputForm.tsx">
      <div className={'flex w-full flex-col gap-3xs'}>
        <Input {...register('email', {})} autoFocus className={cn('h-[47px]')} size={'md'} error={isError} placeholder={t('auth.login.enterEmail')} disabled={isLoading} onKeyDown={e => handleKeyDown(e, handleSubmit(onSubmit, console.error))} data-sentry-element="Input" data-sentry-source-file="EmailInputForm.tsx" />
        {isError && <span className={'text-caption-md-medium text-red-500'}>
            {isRequiredError ? requiredEmail : errorMessage}
          </span>}
      </div>

      <Button disabled={isError || isLoading} size={'lg'} onClick={handleSubmit(onSubmit)} data-sentry-element="Button" data-sentry-source-file="EmailInputForm.tsx">
        {isLoading && <IndicatorIcon />}
        <span>{t('auth.login.email')}</span>
      </Button>
    </div>;
};
const EmailSchema = object({
  email: pipe(string(), nonEmpty('auth.error.requiredEmail'), email('auth.error.requiredEmail'), maxLength(50, 'auth.error.requiredEmail'))
});
export type EmailSchemaType = InferInput<typeof EmailSchema>;